const REGEXP_OS_ANDROID = /android/i;
const REGEXP_OS_IOS = /iphone|ipad|ipod/i;

const REGEXP_APP_WECHAT = /micromessenger/i;
const REGEXP_APP_ZHUZHER = /zhuzher/i;
const REGEXP_APP_LEBANG = /VKStaffAssistant/i;

export const platformUtils = (userAgent?: Window['navigator']['userAgent']) => {
  const { screen, navigator } = window;

  const ua = userAgent || navigator.userAgent;

  const isAndroid = REGEXP_OS_ANDROID.test(ua);

  const isIos = REGEXP_OS_IOS.test(ua);

  const isInZhuzher = REGEXP_APP_ZHUZHER.test(ua);

  const isInZhuYingTai = REGEXP_APP_LEBANG.test(ua);

  const isInWechat = REGEXP_APP_WECHAT.test(ua);

  const isIPhoneX = isIos && screen.height === 812 && screen.width === 375;

  return {
    isAndroid,
    isIos,
    isIPhoneX,
    isInZhuzher,
    isInZhuYingTai,
    isInWechat,
  };
};

const platform = platformUtils();
export default platform;

const mobileRE =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;

const tabletRE = /android|ipad|playbook|silk/i;

export function isMobile() {
  const ua = navigator.userAgent;
  if (typeof ua !== 'string') return false;

  let result = mobileRE.test(ua) || tabletRE.test(ua);

  if (
    !result &&
    navigator &&
    navigator.maxTouchPoints > 1 &&
    ua.indexOf('Macintosh') !== -1 &&
    ua.indexOf('Safari') !== -1
  ) {
    result = true;
  }

  return result;
}
