/* eslint-disable @typescript-eslint/no-var-requires */
/**
 * global.ts 会在app.ts之前运行
 */
import * as Sentry from '@sentry/browser';
import { initToken } from '@/utils/token';

import env, {
  isLocal,
  isInZhuYingTai,
  isInWechat,
  isInZhuzher,
} from '@/utils/env';
import '@/styles/reset.less';
import '@/styles/classes/_base.less';
import '@/styles/classes/_loop.less';
import { isMobile } from './utils/platform';
// import 'antd-mobile/dist/antd-mobile.less';
// 初始化Token
initToken();

// 初始化调试工具，生产会关闭
// 这里会经过webpack编译忽略, 所以需要使用CMD的写法
if ((env.ENV === 'local' || env.ENV === 'sit') && isMobile) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // eslint-disable-next-line global-require
  const VConsole = require('vconsole');
  // eslint-disable-next-line no-new
  new VConsole();
}

// 非本地发送异常到sentry
if (env.ENV === 'uat' || env.ENV === 'prod') {
  const dsn =
    'https://b68dce6b76ca4088a7bf0017681c9383@sentry.vankeservice.com/92'; // 这里需要sentry dsn ，需要自行申请
  if (dsn) {
    Sentry.init({
      dsn,
      release: `${process.env.APP_ENV}-${process.env.APP_VERSION_TIME}-${process.env.APP_VERSION}`,
    });
  }
}
