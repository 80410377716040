// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/azp/agent/_work/27/s/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/prefix/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__register' */'@/pages/prefix/register'), loading: LoadingComponent}),
    "title": "新用户注册",
    "exact": true
  },
  {
    "path": "/prefix/registerfromdptbrowser",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__registerChooseDept' */'@/pages/prefix/registerChooseDept'), loading: LoadingComponent}),
    "title": "选择邀请岗位",
    "exact": true
  },
  {
    "path": "/prefix/registerfrombrowser",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__registerFromBrowser' */'@/pages/prefix/registerFromBrowser'), loading: LoadingComponent}),
    "title": "邀请新用户",
    "exact": true
  },
  {
    "path": "/prefix/registerfromgalaxy",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__registerFromGalaxy' */'@/pages/prefix/registerFromGalaxy'), loading: LoadingComponent}),
    "title": "邀请新用户",
    "exact": true
  },
  {
    "path": "/prefix/registerendback",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__registerFromGalaxy__registerEndBack' */'@/pages/prefix/registerFromGalaxy/registerEndBack'), loading: LoadingComponent}),
    "title": "邀请新用户",
    "exact": true
  },
  {
    "path": "/prefix/exerciseguide",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__exerciseGuide' */'@/pages/prefix/exerciseGuide'), loading: LoadingComponent}),
    "title": "助英台5.0重磅上线",
    "exact": true
  },
  {
    "path": "/prefix/register/end",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__registerEnd' */'@/pages/prefix/registerEnd'), loading: LoadingComponent}),
    "title": "新用户注册",
    "exact": true
  },
  {
    "path": "/prefix/register/choose-role",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__chooseRole' */'@/pages/prefix/chooseRole'), loading: LoadingComponent}),
    "title": "新用户注册",
    "exact": true
  },
  {
    "path": "/prefix/register/success",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__registerSuccess' */'@/pages/prefix/registerSuccess'), loading: LoadingComponent}),
    "title": "新用户注册",
    "exact": true
  },
  {
    "path": "/prefix/password",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__prefix__password' */'@/pages/prefix/password'), loading: LoadingComponent}),
    "title": "重置密码",
    "exact": true
  },
  {
    "path": "/history",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__version__history' */'@/pages/version/history'), loading: LoadingComponent}),
    "title": "历史记录",
    "exact": true
  },
  {
    "path": "/release",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__version__release' */'@/pages/version/release'), loading: LoadingComponent}),
    "title": "版本更新",
    "exact": true
  },
  {
    "path": "/download",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__version__download' */'@/pages/version/download'), loading: LoadingComponent}),
    "title": "下载页面",
    "exact": true
  },
  {
    "path": "/sportQa",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__version__sportQa' */'@/pages/version/sportQa'), loading: LoadingComponent}),
    "title": "常见问题",
    "exact": true
  },
  {
    "path": "/shareDemo",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__version__share' */'@/pages/version/share'), loading: LoadingComponent}),
    "title": "演示页面",
    "exact": true
  },
  {
    "path": "/previewAsset",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asset' */'@/pages/asset'), loading: LoadingComponent}),
    "title": "助英台公共服务",
    "exact": true
  },
  {
    "path": "/previewFile",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asset__preview' */'@/pages/asset/preview'), loading: LoadingComponent}),
    "title": "助英台公共服务",
    "exact": true
  },
  {
    "path": "/shareApp",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asset__shareApp' */'@/pages/asset/shareApp'), loading: LoadingComponent}),
    "title": "分享文件",
    "exact": true
  },
  {
    "path": "/newYear",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__asset__newYear' */'@/pages/asset/newYear'), loading: LoadingComponent}),
    "title": "您的助英台2022年度回顾",
    "exact": true
  },
  {
    "path": "/test",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testPage__test' */'@/pages/testPage/test'), loading: LoadingComponent}),
    "title": "test",
    "exact": true
  },
  {
    "path": "/test/bridge",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testPage__bridge' */'@/pages/testPage/bridge'), loading: LoadingComponent}),
    "title": "bridge",
    "exact": true
  },
  {
    "path": "/test/counter",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testPage__counter' */'@/pages/testPage/counter'), loading: LoadingComponent}),
    "title": "counter",
    "exact": true
  },
  {
    "path": "/test/styles",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__testPage__styles' */'@/pages/testPage/styles'), loading: LoadingComponent}),
    "title": "styles",
    "exact": true
  },
  {
    "path": "/jsbridge/demo",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__jsbridgeDemo__demo' */'@/pages/jsbridgeDemo/demo'), loading: LoadingComponent}),
    "title": "助英台JsBridgeDemo",
    "exact": true
  },
  {
    "path": "/jsbridge/demoDetail",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__jsbridgeDemo__demoDetail' */'@/pages/jsbridgeDemo/demoDetail'), loading: LoadingComponent}),
    "title": "助英台JsBridgeDemo",
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
